<template>
    <component :is="form === undefined ? 'div' : 'b-card'">
        <template>
            <div v-if="hasPermission">
                <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
                    <!-- Form -->
                    <b-form class="p-2" @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm">
                        <b-row>
                            <b-col cols="12">
                                <!-- media -->
                                <b-media no-body>
                                    <b-media-body class="mt-75 ml-75">
                                        <b-row>
                                            <b-col cols="12" sm="4">
                                                <!-- upload button -->

                                                <image-cropper v-model="fileForm" label="manager image" :url.sync="form.thumbnail" />
                                                <b-card-text>Allowed JPG, GIF or PNG. Max size of 800kB </b-card-text>
                                                <!--/ upload button -->
                                            </b-col>
                                            <b-col cols="12" sm="8">
                                                <div class="d-flex h-100 align-items-center justify-content-end">
                                                    <div class="w-50 text-right">
                                                        <b-form-group :label="music_name" label-align="center">
                                                            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="danger" class="mr-75 w-100" :disabled="loading" @click="$refs.uploadMusic.click()">
                                                                <span><i class="WMi-upload" /></span> upload music
                                                            </b-button>
                                                        </b-form-group>
                                                        <input ref="uploadMusic" type="file" class="d-none" @change="handleMusicFile" />
                                                        <audio v-if="uploadedMusic" ref="audio" controls class="w-100 mt-2">
                                                            <source ref="musicFile" :src="music_src" />
                                                        </audio>
                                                    </div>
                                                </div>
                                            </b-col>
                                        </b-row>
                                    </b-media-body>
                                </b-media>
                                <!--/ media -->
                            </b-col>
                        </b-row>
                        <b-row class="mt-3">
                            <!-- Name -->
                            <b-col cols="12" sm="6" md="4">
                                <validation-provider #default="validationContext" name="name" rules="required">
                                    <b-form-group label="Name" label-for="name">
                                        <b-form-input id="name" v-model="form.name" :state="getValidationState(validationContext)" trim />

                                        <b-form-invalid-feedback>
                                            {{ validationContext.errors[0] }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </validation-provider>
                            </b-col>
                            <!-- Arabic Name -->
                            <b-col cols="12" sm="6" md="4">
                                <validation-provider name="arabicName">
                                    <b-form-group label="Arabic Name" label-for="arabic-name">
                                        <b-form-input id="arabic-name" v-model="form.name_ar" trim />
                                    </b-form-group>
                                </validation-provider>
                            </b-col>
                            <!-- Genre -->
                            <b-col cols="12" sm="6" md="4">
                                <validation-provider #default="validationContext" name="genre" rules="required">
                                    <b-form-group label="Genre" label-for="genre">
                                        <v-select v-model="form.genre_id" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :reduce="(val) => val.id" :options="genres" label="name_ar" :clearable="false" input-id="genre" @search="filterGenres" />
                                        <b-form-invalid-feedback>
                                            {{ validationContext.errors[0] }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </validation-provider>
                            </b-col>
                            <!-- Artist -->
                            <b-col cols="12" sm="6" md="4">
                                <validation-provider #default="validationContext" name="artist" rules="required">
                                    <b-form-group label="Artist" label-for="artist">
                                        <v-select v-model="form.artist_id" :loading="isLoading" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :reduce="(val) => val.id" :options="artists" label="name" :clearable="false" input-id="artist" @search="filterArtist" />
                                        <b-form-invalid-feedback>
                                            {{ validationContext.errors[0] }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </validation-provider>
                            </b-col>
                            <b-col cols="12" sm="6" md="4">
                                <!-- <validation-provider #default="validationContext" name="composer"> -->
                                <b-form-group label="Composer" label-for="composer">
                                    <v-select v-model="form.composer_id" :loading="isLoading" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :reduce="(val) => val.id" :options="composers" label="name" :clearable="false" input-id="composer" @search="filterComposer" />
                                    <!-- <b-form-invalid-feedback> -->
                                    <!-- {{ validationContext.errors[0] }} -->
                                    <!-- </b-form-invalid-feedback> -->
                                </b-form-group>
                                <!-- </validation-provider> -->
                            </b-col>
                            <!-- Length -->
                            <b-col cols="12" sm="6" md="4">
                                <validation-provider name="length">
                                    <b-form-group label="Length" label-for="length">
                                        <b-form-input id="length" v-model="form.length" type="number" trim />
                                    </b-form-group>
                                </validation-provider>
                            </b-col>
                            <b-col cols="12">
                                <b-row class="text-center">
                                    <!-- Played Count -->
                                    <b-col cols="12" sm="6" md="4">
                                        <validation-provider name="PlayedCount">
                                            <b-form-group label="Played Count" label-for="played-count">
                                                <b-form-input disabled id="played-count" v-model="form.played_count" type="number" trim />
                                            </b-form-group>
                                        </validation-provider>
                                    </b-col>
                                    <b-col cols="12" sm="6" md="4">
                                        <validation-provider name="Unique Played">
                                            <b-form-group label="Unique Played" label-for="fake_Played">
                                                <b-form-input id="played-count" v-model="form.fake_played" type="number" trim />
                                                <span class="text-danger text-nowrap">{{ fakeValError }}</span>
                                            </b-form-group>
                                        </validation-provider>
                                    </b-col>
                                    <b-col cols="12" sm="6" md="4">
                                        <validation-provider name="Total Played">
                                            <b-form-group label="Total Played" label-for="total_Played">
                                                <b-form-input disabled id="played-count" v-model="form.total_played" type="number" trim />
                                            </b-form-group>
                                        </validation-provider>
                                    </b-col>
                                </b-row>
                            </b-col>
                            <!-- Share Link -->
                            <b-col cols="12" sm="12">
                                <validation-provider #default="validationContext" name="share_link" rules="url">
                                    <b-form-group label="Share link" label-for="share_link" :state="getValidationState(validationContext)">
                                        <b-form-input id="share_link" v-model="form.share_link" :state="getValidationState(validationContext)" trim />
                                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                                            {{ validationContext.errors[0] }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </validation-provider>
                            </b-col>
                            <!-- Description -->
                            <b-col cols="12">
                                <validation-provider name="Description">
                                    <b-form-group label="description" label-for="description">
                                        <b-form-textarea v-model="form.description" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :reduce="(val) => val.value" input-id="description" rows="4" />
                                    </b-form-group>
                                </validation-provider>
                            </b-col>
                            <!-- Lyric -->
                            <b-col cols="12">
                                <validation-provider name="Lyric">
                                    <b-form-group label="Lyric" label-for="lyric">
                                        <b-form-textarea v-model="form.lyric" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :reduce="(val) => val.value" input-id="lyric" rows="4" />
                                    </b-form-group>
                                </validation-provider>
                            </b-col>
                            <!-- Exclusive -->
                            <b-col cols="12">
                                <validation-provider name="Exclusive">
                                    <b-form-group label="Exclusive" label-for="exclusive">
                                        <b-form-checkbox v-model="form.exclusive" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" input-id="exclusive" name="exclusive" switch inline />
                                    </b-form-group>
                                </validation-provider>
                            </b-col>
                            <!-- Form Actions -->
                            <b-col cols="12">
                                <div class="d-flex mt-2">
                                    <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="danger" class="mr-2" type="submit" :disabled="loading">
                                        <span v-show="!loading"> {{ musicId ? "update" : "add" }} </span>
                                        <b-spinner v-show="loading" label="Loading..." />
                                    </b-button>
                                    <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="reset" variant="outline-danger"> reset </b-button>
                                </div>
                            </b-col>
                        </b-row>
                    </b-form>
                </validation-observer>
            </div>
            <div v-else class="text-center">
                <h1>⛔ You are not Allowed to be here ⛔</h1>
            </div>
        </template>
    </component>
</template>

<script>
import { BAlert, BButton, BSpinner, BCard, BCardText, BCol, BForm, BFormFile, BFormGroup, BFormInput, BFormInvalidFeedback, BFormTextarea, BImg, BMedia, BMediaAside, BMediaBody, BRow, BTab, BTabs, BFormCheckbox } from "bootstrap-vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import formValidation from "@core/comp-functions/forms/form-validation";
import { email, required } from "@validations";
import { onMounted, onUnmounted, ref, watch } from "@vue/composition-api";
// eslint-disable-next-line import/no-cycle
import router from "@/router";
import store from "@/store";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
// eslint-disable-next-line import/no-cycle
import { avatarText } from "@core/utils/filter";
// repository
import FileRepository from "@/abstraction/repository/fileRepository";
import MusicRepository from "@/abstraction/repository/musicRepository";
import ArtistRepository from "@/abstraction/repository/artistRepository";
import ComposerRepository from "@/abstraction/repository/composerRepository";
import GenreRepository from "@/abstraction/repository/genresRepository";
// random token
import { makeid } from "@/utils/math";
import debouncedFunction from "@/utils/filter/autocompleteFilter.js";
import useUsersList from "../user/useUsersList";
import userStoreModule from "../user/userStoreModule";

const musicRepository = new MusicRepository();
const artistRepository = new ArtistRepository();
const genreRepository = new GenreRepository();
const composerRepository = new ComposerRepository();

const RANDOM_TOKEN = makeid(50);

export default {
    components: {
        BTab,
        BFormInput,
        BFormInvalidFeedback,
        BFormGroup,
        BForm,
        BSpinner,
        BFormTextarea,
        BFormFile,
        BImg,
        BMedia,
        BTabs,
        BCard,
        BAlert,
        BRow,
        BCol,
        vSelect,
        BButton,
        BCardText,
        BMediaAside,
        BMediaBody,
        BFormCheckbox,
        // Form Validation
        ValidationProvider,
        ValidationObserver,
    },
    directives: {
        Ripple,
    },
    data: () => ({
        required,
        email,
        profileFile: null,
        genres: [],
        artists: [],
        composers: [],
        uploadedMusic: false,
        loading: false,
        isLoading: false,
        music_src: null,
        music_name: null,
    }),
    setup() {
        const blankForm = {
            name: "",
            name_ar: "",
            description: "",
            exclusive: true,
            lyric: "",
            share_link: "",
            played_count: null,
            fake_played: null,
            total_played: null,
            genre_id: "",
            artist_id: "",
            composer_id: "",
            length: null,
            file_batch_id: RANDOM_TOKEN,
        };
        const musicId = ref(router.currentRoute.params.id);
        const fileForm = ref({
            batch_id: RANDOM_TOKEN,
            collection_name: "main_image",
            model_name: "music",
            crop: {},
        });
        const roleOptions = [
            {
                label: "Admin",
                value: "admin",
            },
            {
                label: "Author",
                value: "author",
            },
            {
                label: "Editor",
                value: "editor",
            },
            {
                label: "Maintainer",
                value: "maintainer",
            },
            {
                label: "Subscriber",
                value: "subscriber",
            },
        ];

        const planOptions = [
            {
                label: "Basic",
                value: "basic",
            },
            {
                label: "Company",
                value: "company",
            },
            {
                label: "Enterprise",
                value: "enterprise",
            },
            {
                label: "Team",
                value: "team",
            },
        ];
        const form = ref(blankForm);

        const USER_APP_STORE_MODULE_NAME = "app-user";

        const { refetchData } = useUsersList();
        // Register module
        if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) {
            store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule);
        }

        // UnRegister on leave
        onUnmounted(() => {
            if (store.hasModule(USER_APP_STORE_MODULE_NAME)) {
                store.unregisterModule(USER_APP_STORE_MODULE_NAME);
            }
        });
        const fakeVal = ref();
        onMounted(async () => {
            if (musicId.value) {
                const resource = await musicRepository.show(musicId.value);
                form.value = resource;
                form.value.file_batch_id = RANDOM_TOKEN;
                fakeVal.value = form.value.fake_played;
            }
        });
        const resetform = () => {
            form.value = JSON.parse(JSON.stringify(blankForm));
        };
        const fakeValError = ref();
        watch(
            () => form.value.fake_played,
            (newCount, oldCount) => {
                fakeValError.value = "";
                if (newCount < fakeVal.value) {
                    console.log("its bigger");
                    fakeValError.value = `The Entered Data Shouldn't be Less than ${fakeVal.value} `;
                }
            }
        );
        const onSubmit = async () => {
            console.log(form.value);
            try {
                const fileRepository = new FileRepository();
                if (router.currentRoute.params.id) {
                    if (fileForm.value.file) {
                        await fileRepository.store(fileForm.value);
                    }
                    await musicRepository.update(form.value.id, form.value);
                    await musicRepository.update(form.value.id, form.value);
                    router.push({ name: "apps-musics-list" });
                } else {
                    await fileRepository.store(fileForm.value);
                    await musicRepository.store(form.value);
                    router.push({ name: "apps-musics-list" });
                }
                return true;
            } catch (e) {
                return e;
            }
        };
        const { refFormObserver, getValidationState, resetForm } = formValidation(resetform);
         const hasPermission = ref(false);
        const checkPermissions = () => {
            let logedInUserPermissions = JSON.parse(localStorage.getItem("userData")).permissions.map((item) => {
                return item.name;
            });
            if (logedInUserPermissions.includes("Music.Browse.add")) {
                hasPermission.value = true;
            }
        };
        checkPermissions();
        return {
            hasPermission,
            form,
            fileForm,
            refetchData,
            onSubmit,
            musicId,
            fakeValError,
            refFormObserver,
            getValidationState,
            resetForm,
            avatarText,
            roleOptions,
            planOptions,
        };
    },
    created() {
        this.loadGenres();
        this.loadArtists();
        this.loadComposer();
    },
    methods: {
        filterGenres(search) {
            debouncedFunction(search, async () => {
                const filters = {
                    name_ar: {
                        type: "like",
                        val: search,
                    },
                };
                const params = {
                    filters,
                    pagination: {
                        page: 1,
                    },
                };
                this.isLoading = true;
                try {
                    this.loadGenres(params);
                } catch (err) {
                    console.log(err, "there was an error loading models");
                } finally {
                    this.isLoading = false;
                }
            });
        },
        async loadGenres(param = {}) {
            console.log(param);
            const resource = await genreRepository.index(param);
            this.genres = resource.data;
        },
        filterArtist(search) {
            debouncedFunction(search, async () => {
                const filters = {
                    name: {
                        type: "like",
                        val: search,
                    },
                };
                const params = {
                    filters,
                    pagination: {
                        page: 1,
                    },
                };
                this.isLoading = true;
                try {
                    this.loadArtists(params);
                } catch (err) {
                    console.log(err, "there was an error loading models");
                } finally {
                    this.isLoading = false;
                }
            });
        },
        async loadArtists(param = {}) {
            const resource = await artistRepository.index(param);
            this.artists = resource.data;
        },
        filterComposer(search) {
            debouncedFunction(search, async () => {
                const filters = {
                    name: {
                        type: "like",
                        val: search,
                    },
                };
                const params = {
                    filters,
                    pagination: {
                        page: 1,
                    },
                };
                this.isLoading = true;
                try {
                    this.loadComposer(params);
                } catch (err) {
                    console.log(err, "there was an error loading models");
                } finally {
                    this.isLoading = false;
                }
            });
        },
        async loadComposer(param = {}) {
            const resource = await composerRepository.index(param);
            this.composers = resource.data;
        },
        async handleMusicFile(event) {
            const { files } = event.target;
            const fileRepository = new FileRepository();
            const fileForm = {
                batch_id: RANDOM_TOKEN,
                collection_name: "music",
                model_name: "music",
                file: files[0],
            };
            this.loading = true;
            if (fileForm.file) {
                const response = await fileRepository.store(fileForm);
                if (response) {
                    this.music_src = response.url;
                    this.loading = false;
                    this.uploadedMusic = true;
                    this.music_name = files[0].name;
                    // this.$refs.musicName.classList.value = '';
                }
            }
        },
    },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
